<template>
  <!-- Menu -->
  <ul class="nk-menu nk-menu-main">
    <router-link
      v-for="route in routes"
      :key="route.path"
      :to="route.path"
      custom
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        v-if="route.children.length == 0"
        :class="{ 'nk-menu-item': true, active: isExactActive || isActive }"
      >
        <a :href="href" @click="navigate" class="nk-menu-link">
          <span class="nk-menu-text">{{ route.name }} </span>
        </a>
      </li>

      <li v-else class="nk-menu-item has-sub">
        <a href="#" class="nk-menu-link nk-menu-toggle" data-toggle="dropdown">
          <span class="nk-menu-text">{{ route.name }}</span>
        </a>
        <ul class="nk-menu-sub">
          <li v-for="child in route.children" :key="child" class="nk-menu-item">
            <router-link :to="child.path" class="nk-menu-link"
              ><span>{{ child.name }}</span></router-link
            >
          </li>
        </ul>
      </li>
    </router-link>
  </ul>
</template>

<script>
import useAuth from "@/providers/auth";
export default {
  name: "Menu",
  setup() {
    const { isAdmin } = useAuth();
    const { isSoloVista } = useAuth();
    const routes = [
      {
        path: "/sistema/inicio",
        name: "Inicio",
        children: [],
      },
      {
        path: "/sistema/empleados",
        name: "Empleados",
        children: [],
      },
    ];

    if (isAdmin.value) {
      routes.push({
        path: "/sistema/servicios",
        name: "Servicios",
        children: [],
      });
      routes.push({
        path: "/sistema/usuarios",
        name: "Usuarios",
        children: [],
      });
      routes.push({
        path: "/sistema/configuracion",
        name: "Configuracion",
        children: [],
      });
      routes.push({
        path: "/sistema/bitacora",
        name: "Bitacora",
        children: [],
      });
    }

    if (isSoloVista.value) {
      routes.push({
        path: "/sistema/servicios",
        name: "Servicios",
        children: [],
      });
    }

    return { routes };
  },
};
</script>

<style scoped>
</style>